import React, { useState } from "react"
import { StaticQuery, graphql, Link } from 'gatsby';
import { Navbar } from "react-bootstrap"
import { HamburgerSlider } from "react-animated-burgers"
import Logo from '../../content/assets/logo.internal.svg'

function Menu({ page }) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const is_current_page = ( url ) => {
    const currentUrl = page && page.uri ? page.uri : '/';
    if (typeof url === 'object' && url.length > 0) {
      const obj = url.find((e) => e.url === currentUrl);
      return obj ? 'active' : '';
    }
    return currentUrl === url || currentUrl.includes(url) ? 'active' : '';
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          mainMenu: wpMenu(name: { eq: "Huvudmeny" }) {
            id
            menuItems {
              nodes {
                id
                url
                label
                target
                cssClasses
                childItems {
                  nodes {
                    id
                    url
                    label
                    target
                  }
                }
              }
            }
          }
          miniMenu: wpMenu(name: { eq: "Minimeny" }) {
            id
            menuItems {
              nodes {
                id
                url
                label
                target
                cssClasses
                childItems {
                  nodes {
                    id
                    url
                    label
                    target
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Navbar
          expanded={showMenu}
          bsPrefix={`navbar navbar-expand-md justify-content-end ${showMenu ? 'active' : ''}`}
        >
          <Link to="/" className="navbar-brand">
            <Logo className="kumbro-logo" />
          </Link>
          <HamburgerSlider
            className={`navbar-toggler ml-auto pr-0 hamburger-slider ${
              showMenu ? 'hamburger-active' : ''
            }`}
            aria-controls="navbar"
            isActive={showMenu}
            toggleButton={toggleMenu}
            barColor="#000"
            buttonWidth={36}
          />
          <Navbar.Collapse id="navbar">
            <div className="row w-100">
              <ul className="navbar-nav justify-content-end mb-lg-0 mini-menu order-3 order-md-1">
                {data.miniMenu.menuItems.nodes.map((item, i, arr) => {
                  return (
                    <li
                      className={`nav-item ${is_current_page(
                        item.childItems.nodes
                      )}`}
                      key={item.id}
                    >
                      <Link
                        to={item.url}
                        className={`nav-link ${is_current_page(item.url)} ${
                          item.cssClasses
                        }`}
                        target={item.target}
                      >
                        {item.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className="w-100 order-2"></div>
              <ul className="navbar-nav justify-content-end  mb-2 mb-lg-0 main-menu order-1 order-md-3">
                {data.mainMenu.menuItems.nodes.map((item, i, arr) => {
                  return (
                    <li
                      className={`nav-item ${is_current_page(
                        item.childItems.nodes
                      )}`}
                      key={item.id}
                    >
                      <Link
                        to={item.url}
                        className={`nav-link ${is_current_page(item.url)} ${
                          item.cssClasses
                        }`}
                        target={item.target}
                      >
                        ›{item.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Navbar.Collapse>
        </Navbar>
      )}
    />
  );
}

export default Menu
