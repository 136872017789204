import React from "react"
import Menu from "./menu"
import Footer from './footer';
const Layout = (props) => {
  const { children, isHomePage, page, location } = props

  return (
    <div {...props} data-is-root-path={isHomePage}>
      <a className="skip-main" href="#main">
        Hoppa över till innehåll
      </a>
      <header>
        <Menu location={location} page={page} />
      </header>

      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout
