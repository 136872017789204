import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Logo from "../../content/assets/logo.internal.svg"
import Eu from '../../content/assets/eu-logo-jordbruksfonden.internal.svg';
import parse from 'html-react-parser';
const component = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          sidfot {
            footer {
              footerBlocks {
                text
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer>
        <div className="navbar-brand">
          <Logo />
        </div>
        <div className="container-fluid">
          <div className="row item-wrap">
            {data.wp.sidfot.footer.footerBlocks.map((item, i, arr) => (
                <div className={`col-12 ${i === 2 ? 'col-md-2 align-self-center' : 'col-md-3 offset-md-1'} item`} key={i}>
                  {parse(item.text || '')}
                </div>
            ))}
            <div className={`col-12 col-md-2 align-self-center item text-center`}>
                <Eu className="eu-logo" />
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

export default component;
