import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, seo, featuredImage }) => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          seo {
            schema {
              inLanguage
              companyOrPerson
              siteName
            }
            openGraph {
              defaultImage {
                localFile {
                  publicURL
                }
              }
            }
          }
          generalSettings {
            description
            title
          }
        }
      }
    `
  );
  const openGraphImage = seo?.opengraphImage?.localFile?.publicURL;
  const defaultImage = wp?.seo?.openGraph?.defaultImage?.localFile?.publicURL;
  const metaDescription =
    seo?.opengraphDescription ||
    seo?.metaDesc ||
    wp.generalSettings?.description;
  const defaultTitle = wp.generalSettings?.title;
  const image =
    openGraphImage === defaultImage
      ? featuredImage || openGraphImage
      : openGraphImage || defaultImage;

  return (
    <Helmet
      htmlAttributes={{
        lang: wp.seo.schema.inLanguage,
      }}
      title={title}
      titleTemplate={
        defaultTitle !== title ? `%s | ${defaultTitle}` : defaultTitle
      }
    >
      {metaDescription && (
        <meta
          name="description"
          property="og:description"
          content={metaDescription}
        />
      )}
      {metaDescription && (
        <meta property="twitter:description" content={metaDescription} />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={wp.seo.schema.siteName} />
      <meta property="og:locale" content={wp.seo.schema.inLanguage} />
      <meta property="og:type" content={seo.opengraphType} />
      {image && <meta property="og:image" content={encodeURI(image)} />}
      <meta property="twitter.card" content={`summary_large_image`} />
      <meta property="twitter.title" content={title} />
      {image && <meta property="twitter:image" content={encodeURI(image)} />}
      {seo?.canonical && <link rel="canonical" href={seo.canonical} />}
      {seo?.opengraphType === 'article' && seo?.opengraphPublishedTime && (
        <meta
          property="article:published_time"
          content={seo?.opengraphPublishedTime}
        />
      )}
      {seo?.opengraphType === 'article' && seo?.opengraphModifiedTime && (
        <meta
          property="article:modified_time"
          content={seo?.opengraphModifiedTime}
        />
      )}
      {seo?.schema?.raw && (
        <script type="application/ld+json">{seo.schema.raw}</script>
      )}

      <meta name="robots" content={seo?.metaRobotsNoindex} />
      <meta name="robots" content={seo?.metaRobotsNofollow} />
    </Helmet>
  );
};

SEO.defaultProps = {
  featuredImage: false,
  seo: false,
  title: false,
};

export default SEO;
