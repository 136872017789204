import React from "react"
import Img from "gatsby-image"
import parse from 'html-react-parser'

const Component = ({ item, num }) => {
  const { text, color, small, mp4, webm } = item

  const sources = item?.imageMobile
    ? [
        item?.imageMobile?.localFile?.childImageSharp?.fluid,
        {
          ...item.image.localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : item?.image?.localFile?.childImageSharp.fluid
    ? item?.image?.localFile?.childImageSharp.fluid
    : false;

  return (
    <section className={`top ${small ? 'small' : ''}`}>
      {!!item?.image && !mp4 && !webm && (
        <div className="image-wrap">
          <Img
            fluid={sources}
            className="no-padding-image"
            alt={item?.image?.altText}
            loading="eager"
            fadeIn={false}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
      )}

      {!!mp4 && !!webm && (
        <div className="video-wrap">
          <video autoPlay muted playsInline loop>
            <source src={mp4?.localFile?.publicURL} type="video/mp4" />
            <source src={webm?.localFile?.publicURL} type="video/webm" />
          </video>
        </div>
      )}
      <div className="container-fluid position-relative">
        <div className="row align-content-center">
          <div className="col-12 col-md-7" style={{ color }}>
            {!!text && typeof text === 'string' && (
              <>{num === 0 ? <h1>{parse(text || '')}</h1> : <h2>{parse(text || '')}</h2>}</>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Component
